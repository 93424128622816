<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9998;">
        <md-content style="width: 90vw;">
            <div class="page-content-1" style="height: 95vh; overflow: auto; width: 90%;">
                <div class="grid">
                    <div :class="action == 'explain' ? 'row no-gutters page-list-header explain' : 'row no-gutters page-list-header'" >
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action">
                                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                                <md-button v-shortkey="['ctrl', 'i']" @shortkey="close()" @click="close()" class="md-raised">Đóng<md-tooltip>Đóng (Ctrl + Q)</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body" style="margin-bottom: 30px;">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div :class="action == 'explain' ? 'group-box explain' : 'group-box'" style="margin-top:10px;">
                                        <div class="group-title">THÔNG TIN ĐIỀU ĐỘNG</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field :class="{'md-invalid': submitted && $v.entity.jobCode.$error }">
                                                        <label for="jobCode">Mã phiếu<span class="label-require">(*)</span></label>
                                                        <md-input disabled name="jobCode" v-model="entity.jobCode"></md-input>
                                                        <span class="md-error" v-if="submitted && !$v.entity.jobCode.required">Vui lòng nhập mã phiếu</span>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field :class="{'md-invalid': submitted && $v.entity.planId.$error }">
                                                        <label for="branchId">Loại hình vận chuyển<span class="label-require">(*)</span></label>
                                                        <md-select v-model="entity.planId" name="planId" id="planId">
                                                            <md-option v-for="item in planHeavyList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                                        </md-select>
                                                        <span class="md-error" v-if="submitted && !$v.entity.planId.isSelected">Vui lòng chọn loại hình vận chuyển</span>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="row">
                                                        <div class="col l-6 m-6 c-12">
                                                            <md-datepicker v-model="entity.submitDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.submitDate.$error }">
                                                                <label>Ngày yêu cầu<span class="label-require">(*)</span></label>
                                                                <span class="md-error" v-if="submitted && !$v.entity.submitDate.required">Vui lòng chọn ngày yêu cầu</span>
                                                            </md-datepicker>
                                                        </div>
                                                        <div class="col l-6 m-6 c-12">
                                                            <md-datepicker v-model="entity.jobDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.jobDate.$error }">
                                                                <label>Ngày thực hiện<span class="label-require">(*)</span></label>
                                                                <span class="md-error" v-if="submitted && !$v.entity.jobDate.required">Vui lòng chọn ngày thực hiện</span>
                                                            </md-datepicker>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                                            <label>Nhân viên điều xe<span class="label-require">(*)</span></label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openStaff()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field>
                                                        <label for="note">Nội dung</label>
                                                        <md-input name="note" v-model="entity.note"></md-input>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="radio-group">
                                                        <label>Trạng thái</label>
                                                        <div class="form-control">
                                                            <md-radio v-model="entity.status" :value="1">Chưa hoàn thành</md-radio>
                                                            <md-radio v-model="entity.status" :value="2">Hoàn thành</md-radio>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div :class="action == 'explain' ? 'group-box explain' : 'group-box'" style="margin-top:20px;padding-bottom: 0;">
                                        <div class="group-title">PHƯƠNG TIỆN</div>
                                        <div class="box-content">
                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                <md-button @click="addVehicelRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                            </div>
                                            <table class="data-table-2"> 
                                                <thead> 
                                                    <tr>
                                                        <th style="width:100px;">#</th> 
                                                        <th style="width:25%;">Đơn vị vận chuyển</th> 
                                                        <th style="width:20%;">Phương tiện</th> 
                                                        <th style="width:20%;">Lái xe</th> 
                                                        <th style="width:25%;">Ghi chú</th> 
                                                        <th style="width:120px">Hành động</th> 
                                                    </tr> 
                                                </thead> 
                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                </tbody>
                                                <tbody v-if="loading == false"> 
                                                    <tr v-for="(item, index) in entity.vehicleList" :key="'vehicle-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                        <td class="center">{{index + 1}}</td>
                                                        <td class="center">
                                                            <div class="grid-control">
                                                                <input v-model="item.company.companyAlias" :class="{'is-error': $v.entity.vehicleList.$each[index].companyId.$error}" class="form-control" type="text">
                                                                <md-button @click="openCompany(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm công ty</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                        </td> 
                                                        <td class="center">
                                                            <div v-if="item.companyId == 1 || item.companyId == 0" class="grid-control">
                                                                <input v-model="item.vehicle.licensePlate" :class="{'is-error': $v.entity.vehicleList.$each[index].vehicleId.$error}" class="form-control" type="text">
                                                                <md-button @click="openVehicle(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm phương tiện</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                            <div v-else class="grid-control">
                                                                <input v-model="item.vehicleOutside.licensePlate" :class="{'is-error': $v.entity.vehicleList.$each[index].vehicleId.$error}" class="form-control" type="text">
                                                                <md-button @click="openVehicle(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm phương tiện</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                        </td> 
                                                        <td class="center">
                                                            <div v-if="item.companyId == 1 || item.companyId == 0" class="grid-control">
                                                                <input v-model="item.driver.fullName" class="form-control" type="text">
                                                                <md-button @click="openDriver(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm lái xe</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                            <div v-else class="group-control">
                                                                <input v-model="item.driverName" class="form-control" type="text">
                                                            </div>
                                                        </td> 
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <input v-model="item.note" class="form-control" type="text">
                                                            </div>
                                                        </td>
                                                        <td class="grid-action">
                                                            <a @click="delVehicelRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                        </td> 
                                                    </tr> 
                                                </tbody> 
                                            </table> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div :class="action == 'explain' ? 'group-box explain' : 'group-box'" style="margin-top:20px; padding-bottom: 0;">
                                        <div class="group-title">HÀNH TRÌNH</div>
                                        <div class="box-content">
                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                <md-button @click="addRouteRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                            </div>
                                            <table class="data-table-2"> 
                                                <thead> 
                                                    <tr> 
                                                        <th style="width:100px;">Hành động</th>
                                                        <th style="width:150px;">Thứ tự</th>
                                                        <th style="width:28%;">Loại địa điểm</th>
                                                        <th style="width:55%;">Địa điểm<span class="label-require">(*)</span></th> 
                                                    </tr> 
                                                </thead> 
                                                <tbody v-if="loading == true" style="height: 50px;"> 
                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                </tbody>
                                                <tbody v-if="loading == false"> 
                                                    <tr v-for="(item, index) in entity.routes" :key="'route-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                        <td class="grid-action">
                                                            <a @click="delRouteRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                        </td>
                                                        <td class="center">
                                                            {{ (index + 1) }}
                                                        </td>
                                                        <td class="center">
                                                            <md-field>
                                                                <md-select v-model="item.type" name="type">
                                                                    <md-option v-for="item in routeTypeList" :key="'location-type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                                                </md-select>
                                                            </md-field>
                                                        </td>
                                                        <td class="center">
                                                            <div class="grid-control">
                                                                <input v-model="item.location.locationName" :class="{'is-error': $v.entity.routes.$each[index].locationId.$error}" class="form-control" type="text">
                                                                <md-button @click="openLocation(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm địa điểm</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody> 
                                            </table> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div :class="action == 'explain' ? 'group-box explain' : 'group-box'" style="margin-top:20px; padding-bottom: 0;">
                                        <div class="group-title">DANH SÁCH KIỆN HÀNG</div>
                                        <div class="box-content">
                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                <md-button @click="loadAll()" class="md-primary"><md-icon>add_card</md-icon>Lấy danh sách kiện hàng<md-tooltip>Lấy danh sách kiện hàng</md-tooltip></md-button>
                                            </div>
                                            <table class="data-table-2"> 
                                                <thead> 
                                                    <tr> 
                                                        <th style="width:50px;">#</th> 
                                                        <th style="width:10%;">Hành động</th>
                                                        <th style="width:20%;">PkgNo</th>
                                                        <th style="width:30%;">Tên hàng</th>
                                                        <th style="width:20%;">Ngày giao/nhận</th>
                                                        <th style="width:20%;">Đơn vị</th> 
                                                    </tr>
                                                </thead> 
                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                </tbody>
                                                <tbody v-if="loading == false && entity.details.length > 0"> 
                                                    <tr v-for="(item, index) in entity.details" :key="'item-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                        <td class="center">{{index + 1}}</td>
                                                        <td class="grid-action">
                                                            <a @click="delDetail(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                        </td>
                                                        <td class="left">
                                                            {{item.orderDetail.pkgNo}}
                                                        </td>
                                                        <td class="left">
                                                            {{item.orderDetail.goods.goodsName}}
                                                        </td>
                                                        <td class="center">
                                                            {{item.orderDetail.dateOfDelivery}}
                                                        </td> 
                                                        <td class="left">
                                                            {{item.orderDetail.unit.unitName}}
                                                        </td>
                                                    </tr> 
                                                </tbody> 
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="action == 'crud'" class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div :class="action == 'explain' ? 'group-box explain' : 'group-box'" style="margin-top:20px; padding-bottom: 30px;">
                                        <div class="group-title">DỰ TOÁN</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ THUÊ NGOÀI</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(5)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th style="width:50px;">#</th> 
                                                                        <th style="width:90px;">Hành động</th>
                                                                        <th style="width:200px;">Đơn vị<span class="label-require">(*)</span></th> 
                                                                        <th style="width:200px;">Tên phí<span class="label-require">(*)</span></th> 
                                                                        <th style="width:100px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th style="width:120px;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:100px;">VAT</th>
                                                                        <th style="width:120px;">Thành tiền</th>
                                                                        <th class="left" style="width:250px;">Nội dung</th>
                                                                    </tr>
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 5)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.company.companyAlias" class="form-control" type="text">
                                                                                <md-button @click="openTransport(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm công ty</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ NHIÊN LIỆU</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(1)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th style="width:50px;">#</th> 
                                                                        <th style="width:90px;">Hành động</th>
                                                                        <th style="width:200px;">Phương tiện<span class="label-require">(*)</span></th> 
                                                                        <th style="width:200px;">Tên phí<span class="label-require">(*)</span></th> 
                                                                        <th class="left" style="width:250px;">Nội dung</th>
                                                                        <th style="width:100px;">Định mức<span class="label-require">(*)</span></th>
                                                                        <th style="width:100px;">Số KM<span class="label-require">(*)</span></th>
                                                                        <th style="width:100px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th style="width:120px;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:100px;">VAT</th>
                                                                        <th style="width:120px;">Thành tiền</th>
                                                                    </tr>
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 1)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.vehicle.licensePlate" class="form-control" type="text">
                                                                                <md-button @click="openVehicle2(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phương tiện</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumFuel(obj)" v-model="obj.budget" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumFuel(obj)" v-model="obj.km" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumFuel(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumFuel(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumFuel(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ NHÂN CÔNG</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(2)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th style="width:50px;">#</th> 
                                                                        <th style="width:90px;">Hành động</th>
                                                                        <th style="width:200px;">Nhân sự<span class="label-require">(*)</span></th> 
                                                                        <th style="width:250px;">Tên phí<span class="label-require">(*)</span></th>
                                                                        <th style="width:200px;">Loại<span class="label-require">(*)</span></th> 
                                                                        <th style="width:80px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th style="width:120px;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:80px;">VAT</th>
                                                                        <th style="width:120px;">Thành tiền</th>
                                                                        <th class="left" style="width:350px;">Ghi chú</th>
                                                                    </tr>
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 2)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.staff.fullName" class="form-control" type="text">
                                                                                <md-button @click="openStaff2(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select v-model="obj.type" name="type" id="type">
                                                                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px; padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ HÀNH CHÍNH</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(3)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th style="width:50px;">#</th> 
                                                                        <th style="width:90px;">Hành động</th>
                                                                        <th style="width:300px;">Tên phí<span class="label-require">(*)</span></th> 
                                                                        <th style="width:150px;">Loại<span class="label-require">(*)</span></th> 
                                                                        <th style="width:120px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th style="width:150px;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:120px;">VAT</th>
                                                                        <th style="width:150px;">Thành tiền</th>
                                                                        <th class="left" style="width:350px;">Ghi chú</th>
                                                                    </tr>
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 3)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select v-model="obj.type" name="type" id="type">
                                                                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ KHÁC</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(4)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th style="width:50px;">#</th> 
                                                                        <th style="width:90px;">Hành động</th>
                                                                        <th style="width:300px;">Tên phí<span class="label-require">(*)</span></th> 
                                                                        <th style="width:150px;">Loại<span class="label-require">(*)</span></th> 
                                                                        <th style="width:120px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th style="width:150px;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:120px;">VAT</th>
                                                                        <th style="width:150px;">Thành tiền</th>
                                                                        <th class="left" style="width:350px;">Ghi chú</th>
                                                                    </tr>
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 4)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select v-model="obj.type" name="type" id="type">
                                                                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="action == 'explain'" class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="group-box explain" style="margin-top:20px; padding-bottom: 30px;">
                                        <div class="group-title">DỰ TOÁN</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ THUÊ NGOÀI</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(5)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th rowspan="2" style="width:50px;">#</th> 
                                                                        <th rowspan="2" style="width:90px;">Hành động</th>
                                                                        <th rowspan="2" style="width:200px;">Đơn vị<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:200px;">Tên phí<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:100px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th class="center" colspan="4" style="width:340px; background-color: antiquewhite;">Dự toán</th>
                                                                        <th class="center" colspan="4" style="width:340px; background-color: aquamarine;">Giải chi</th>
                                                                    </tr>
                                                                    <tr> 
                                                                        <th style="width:120px;background-color: antiquewhite;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:100px;background-color: antiquewhite;">VAT</th>
                                                                        <th style="width:120px;background-color: antiquewhite;">Thành tiền</th>
                                                                        <th style="width:150px;background-color: antiquewhite;">Ghi chú</th>
                                                                        <th style="width:120px;background-color: aquamarine;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:100px;background-color: aquamarine;">VAT</th>
                                                                        <th style="width:120px;background-color: aquamarine;">Thành tiền</th>
                                                                        <th style="width:150px;background-color: aquamarine;">Ghi chú</th>
                                                                    </tr>
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 5)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.company.companyAlias" class="form-control" type="text">
                                                                                <md-button @click="openTransport(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm công ty</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSum(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSum(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSum(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.explainPrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.explainVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.explainAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.explainNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ NHIÊN LIỆU</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(1)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th rowspan="2" style="width:50px;">#</th> 
                                                                        <th rowspan="2" style="width:90px;">Hành động</th>
                                                                        <th rowspan="2" style="width:200px;">Phương tiện<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:200px;">Tên phí<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:100px;">ĐM(/100km)<span class="label-require">(*)</span></th>
                                                                        <th rowspan="2" style="width:100px;">Số KM<span class="label-require">(*)</span></th>
                                                                        <th rowspan="2" style="width:100px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th class="center" colspan="4" style="width:340px; background-color: antiquewhite;">Dự toán</th>
                                                                        <th class="center" colspan="4" style="width:340px;background-color: aquamarine;">Giải chi</th>
                                                                    </tr>
                                                                    <tr> 
                                                                        <th style="width:120px;background-color: antiquewhite;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:100px;background-color: antiquewhite;">VAT</th>
                                                                        <th style="width:120px;background-color: antiquewhite;">Thành tiền</th>
                                                                        <th class="left" style="width:250px;background-color: antiquewhite;">Nội dung</th>
                                                                        <th style="width:120px;background-color: aquamarine;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:100px;background-color: aquamarine;">VAT</th>
                                                                        <th style="width:120px;background-color: aquamarine;">Thành tiền</th>
                                                                        <th class="left" style="width:250px;background-color: aquamarine;">Nội dung</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 1)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.vehicle.licensePlate" class="form-control" type="text">
                                                                                <md-button @click="openVehicle2(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phương tiện</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSumFuel(obj)" v-model="obj.budget" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSumFuel(obj)" v-model="obj.km" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSumFuel(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSumFuel(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSumFuel(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input :disabled="true" v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumFuel(obj)" v-model="obj.explainPrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumFuel(obj)" v-model="obj.explainVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.explainAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.explainNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ NHÂN CÔNG</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(2)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th rowspan="2" style="width:50px;">#</th> 
                                                                        <th rowspan="2" style="width:90px;">Hành động</th>
                                                                        <th rowspan="2" style="width:200px;">Nhân sự<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:250px;">Tên phí<span class="label-require">(*)</span></th>
                                                                        <th rowspan="2" style="width:200px;">Loại<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:80px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th class="center" colspan="4" style="width:340px;background-color: antiquewhite;">Dự toán</th>
                                                                        <th class="center" colspan="4" style="width:340px;background-color: aquamarine;">Giải chi</th>
                                                                    </tr>
                                                                    <tr> 
                                                                        <th style="width:120px;background-color: antiquewhite;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:80px;background-color: antiquewhite;">VAT</th>
                                                                        <th style="width:120px;background-color: antiquewhite;">Thành tiền</th>
                                                                        <th class="left" style="width:350px;background-color: antiquewhite;">Ghi chú</th>
                                                                        <th style="width:120px;background-color: aquamarine;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:80px;background-color: aquamarine;">VAT</th>
                                                                        <th style="width:120px;background-color: aquamarine;">Thành tiền</th>
                                                                        <th class="left" style="width:350px;background-color: aquamarine;">Ghi chú</th>
                                                                    </tr>
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 2)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.staff.fullName" class="form-control" type="text">
                                                                                <md-button @click="openStaff2(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select v-model="obj.type" name="type" id="type">
                                                                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true"  @change="calSum(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true"  @change="calSum(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true"  @change="calSum(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input :disabled="true" v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.explainPrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.explainVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.explainAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.explainNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px; padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ HÀNH CHÍNH</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(3)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th rowspan="2" style="width:50px;">#</th> 
                                                                        <th rowspan="2" style="width:90px;">Hành động</th>
                                                                        <th rowspan="2" style="width:300px;">Tên phí<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:150px;">Loại<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:120px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th class="center" colspan="4" style="width:340px;background-color: antiquewhite;">Dự toán</th>
                                                                        <th class="center" colspan="4" style="width:340px;background-color: aquamarine;">Giải chi</th>
                                                                    </tr>
                                                                    <tr> 
                                                                        <th style="width:150px;background-color: antiquewhite;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:120px;background-color: antiquewhite;">VAT</th>
                                                                        <th style="width:150px;background-color: antiquewhite;">Thành tiền</th>
                                                                        <th class="left" style="width:350px;background-color: antiquewhite;">Ghi chú</th>
                                                                        <th style="width:150px;background-color: aquamarine;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:120px;background-color: aquamarine;">VAT</th>
                                                                        <th style="width:150px;background-color: aquamarine;">Thành tiền</th>
                                                                        <th class="left" style="width:350px;background-color: aquamarine;">Ghi chú</th>
                                                                    </tr>
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 3)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select v-model="obj.type" name="type" id="type">
                                                                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSum(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSum(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSum(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input :disabled="true" v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.explainPrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.explainVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.explainAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.explainNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                        <div class="group-title">DỰ TOÁN CHI PHÍ KHÁC</div>
                                                        <div class="box-content">
                                                            <div class="tool-bar" style="margin-top: 10px !important;">
                                                                <md-button @click="addEstimateRow(4)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            </div>
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th rowspan="2" style="width:50px;">#</th> 
                                                                        <th rowspan="2" style="width:90px;">Hành động</th>
                                                                        <th rowspan="2" style="width:300px;">Tên phí<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:150px;">Loại<span class="label-require">(*)</span></th> 
                                                                        <th rowspan="2" style="width:120px;">Số lượng<span class="label-require">(*)</span></th>
                                                                        <th class="center" colspan="4" style="width:340px;background-color: antiquewhite;">Dự toán</th>
                                                                        <th class="center" colspan="4" style="width:340px;background-color: aquamarine;">Giải chi</th>
                                                                    </tr>
                                                                    <tr> 
                                                                        <th style="width:150px;background-color: antiquewhite;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:120px;background-color: antiquewhite;">VAT</th>
                                                                        <th style="width:150px;background-color: antiquewhite;">Thành tiền</th>
                                                                        <th class="left" style="width:350px;background-color: antiquewhite;">Ghi chú</th>
                                                                        <th style="width:150px;background-color: aquamarine;">Đơn giá<span class="label-require">(*)</span></th>
                                                                        <th style="width:120px;background-color: aquamarine;">VAT</th>
                                                                        <th style="width:150px;background-color: aquamarine;">Thành tiền</th>
                                                                        <th class="left" style="width:350px;background-color: aquamarine;">Ghi chú</th>
                                                                    </tr>
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(obj, i) in entity.estimate.details.filter(x => x.estimateType == 4)" :key="obj.id" :class="{ 'odd': i % 2 !== 0 }"> 
                                                                        <td class="center">{{i + 1}}</td>
                                                                        <td class="grid-action">
                                                                            <a @click="delEstimateRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="obj.fee.feeName" class="form-control" type="text">
                                                                                <md-button @click="openFee(obj)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select v-model="obj.type" name="type" id="type">
                                                                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSum(obj)" v-model="obj.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSum(obj)" v-model="obj.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" @change="calSum(obj)" v-model="obj.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input :disabled="true" v-model="obj.estimateNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.explainPrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(obj)" v-model="obj.explainVat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="obj.explainAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="obj.explainNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <driverList ref="driverList" @close="closeDriver"/>
                <vehicleList ref="vehicleList" @close="closeVehicle"/>
                <vehicleOutsideList ref="vehicleOutsideList" @close="closeVehicleOutside"/>
                <vehicle2List ref="vehicle2List" @close="closeVehicle2"/>
                <staffList ref="staffList" @close="closeStaff"/>
                <staff2List ref="staff2List" @close="closeStaff2"/>
                <locationList ref="locationList" @close="closeLocation"/>
                <feeList ref="feeList" @close="closeFee"/>
                <companyList ref="companyList" @close="closeCompany"/>
                <transportList ref="transportList" @close="closeTransport"/>
                <goodsList ref="goodsList" @close="closeGoods"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import jobHeavyService from '../../../api/jobHeavyService';
import staffService from '../../../api/staffService';
import messageBox from '../../../utils/messageBox';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import driverList from '../../../components/popup/_DriverList.vue';
import vehicleList from '../../../components/popup/_VehicleList.vue';
import vehicle2List from '../../../components/popup/_VehicleList.vue';
import staffList from '../../../components/popup/_StaffList.vue';
import staff2List from '../../../components/popup/_StaffList.vue';
import locationList from '../../../components/popup/_LocationList.vue';
import feeList from '../../../components/popup/_FeeList.vue';
import common from '../../../mixins';
import companyList from '../../../components/popup/_TransportList.vue';
import transportList from '../../../components/popup/_TransportList.vue';
import vehicleOutsideList from '../../../components/popup/_VehicleOutsideList.vue';
import goodsList from '../../../components/popup/_OrderDetailHeavyList.vue';

export default({
    components: {
        goodsList,
        transportList,
        vehicleOutsideList,
        driverList,
        vehicleList,
        vehicle2List,
        staffList,
        staff2List,
        locationList,
        feeList,
        companyList
    },
    metaInfo: {
        title: 'Thêm/Cập nhật điều xe STST',
    },
    data() {
        return {
            title: '',
            showDialog: false,
            loading: false,
            id: 0,
            submitted: false,
            loadingTab: false,
            planHeavyList: common.planHeavyList,
            companyType: common.companyType.transport,
            entity: { id: 0, estimate: { details: [] }, jobCode: '', jobDate: '', planId: 1, status: 1, submitDate: common.dateNow, staffId: 0, staff: { fullName: '' }, vehicleList: [], details: [], routes: [] },
            staffName: '',
            staffs: [],
            tab: 0,
            numberF: common.numberF,
            vatF: common.vatF,
            currencyF: common.currencyF2,
            routeTypeList: common.routeType,
            types: common.typeFeeHeavy,
            selectedObject: null,
            action: 'crud',
            order: null
        }
    },
    created() {
        
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        async openEdit(id){
            this.action = 'crud';
            this.title = 'Cập nhật điều xe STST';
            this.id = id;
            this.getById();
            this.showDialog = true;
        },

        async openExplain(id){
            this.action = 'explain';
            this.title = 'Giải chi điều xe STST';
            this.id = id;
            this.getById();
            this.showDialog = true;
        },

        loadAll(){
            this.$refs.goodsList.open(this.order.id);
        },

        closeGoods(selectedList){
            selectedList.map(item => {
                let obj = {
                    id: this.uuidv4(10000 + item.id),
                    jobId: 0,
                    orderId: item.orderId,
                    orderDetailId: item.id,
                    orderDetail: item,
                    driverId: null,
                    vehilceId: null,
                    note: '',
                    status: 1
                };
                const index = this.entity.details.findIndex(x => x.orderDetailId == item.id);
                if(index == -1){
                    this.entity.details.push(obj);
                }   
            });
        },

        async open(order){
            this.id = 0;
            this.order = order;
            this.action = 'crud';
            this.title = 'Thêm mới điều xe STST';
            this.showDialog = true;
            this.getByAccount();
            this.entity.details = [];
            order.details.map(obj => {
                let item = {
                    id: this.uuidv4(10000 + obj.id),
                    jobId: 0,
                    orderId: obj.orderId,
                    orderDetailId: obj.id,
                    orderDetail: obj,
                    driverId: null,
                    vehilceId: null,
                    note: '',
                    status: 1
                };
                this.entity.details.push(item);
            });
            this.entity.routes = [];
            if(order.details.length > 0){
                this.entity.routes = order.details[0].routes;
            }
        },

        //Fee
        closeFee(item){
            this.selectedObject.fee = item;
            this.selectedObject.feeId = item.id;
            this.$refs.feeList.close();
        },

        openFee(obj){
            this.selectedObject = obj;
            this.$refs.feeList.open(2);
        },

        calSum(obj){
            let amountNotVat = parseFloat(obj.quantity) * parseFloat(obj.estimatePrice);
            let vat = amountNotVat * parseFloat(obj.estimateVat / 100);
            obj.estimateAmount = amountNotVat + vat;

            let explainAmountNotVat = parseFloat(obj.quantity) * parseFloat(obj.explainPrice);
            let explainVat = explainAmountNotVat * parseFloat(obj.explainVat / 100);
            obj.explainAmount = explainAmountNotVat + explainVat;
        },

        calSumFuel(obj){
            let quantity = parseFloat(obj.budget) * (parseFloat(obj.km) / 100);
            obj.quantity = quantity;
            let amountNotVat = parseFloat(quantity) * parseFloat(obj.estimatePrice);
            let vat = amountNotVat * parseFloat(obj.estimateVat / 100);
            obj.estimateAmount = amountNotVat + vat;

            let explainAmountNotVat = parseFloat(quantity) * parseFloat(obj.explainPrice);
            let explainVat = explainAmountNotVat * parseFloat(obj.explainVat / 100);
            obj.explainAmount = explainAmountNotVat + explainVat;
        },
        
        delDetail(id){
            const index = this.entity.details.findIndex(x => x.id == id);
            this.entity.details.splice(index, 1);
        },

        addVehicelRow(){
            let item = { id: 'id_' + common.getFakeId(), companyId: 0, company: { companyAlias: '' }, vehicleId: 0, vehicle: { licensePlate: '' }, driverId: 0, driver: { fullName: '' }, note: '' };
            this.entity.vehicleList.push(item);
        },

        delVehicelRow(item){
            const index = this.entity.vehicleList.findIndex(x => x.id == item.id);
            this.entity.vehicleList.splice(index, 1);
        },

        addEstimateRow(type){
            let feeType = 1;
            if(type == 2){
                feeType = 4
            }
            let item = { id: 'id_' + common.getFakeId(), companyId: 0, company: { companyAlias: '' }, estimateType: type, staffId: 0, staff: { fullName: '' }, vehicleId: 0, vehicle: { licensePlate: '' }, fee: { feeCode: '', feeName: '' }, feeId: 0, type: feeType, quantity: 0, estimatePrice: 0, estimateVat: 0, estimateAmount: 0, note: '' };
            this.entity.estimate.details.push(item);
        },
        
        delEstimateRow(obj){
            const index = this.entity.estimate.details.findIndex(x => x.id == obj.id);
            this.entity.estimate.details.splice(index, 1);
        },

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.entity.vehicleList.length == 0){
                messageBox.showWarning("Vui lòng thêm phương tiện");
                return;
            }
            else if(this.entity.routes.length == 0){
                messageBox.showWarning("Vui lòng thêm hành trình");
                return;
            }
            else if(this.entity.details.length == 0){
                messageBox.showWarning("Vui lòng thêm hàng hóa vận chuyển");
                return;
            }
            else if(this.entity.estimate.details.length == 0){
                messageBox.showWarning("Vui lòng thêm dự toán");
                return;
            }
            if(this.entity.details.length > 0){
                this.entity.details = this.entity.details.filter(item => !(item.id == 0));
                this.entity.details.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.entity.estimate.details.length > 0){
                this.entity.estimate.details.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.entity.vehicleList.length > 0){
                this.entity.vehicleList.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.entity.routes.length > 0){
                this.entity.routes.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.id > 0){
                if(this.action == 'explain'){
                    this.explain();
                }
                else{
                    this.edit();
                }
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            jobHeavyService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('close');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            this.entity.action = this.action;
            jobHeavyService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('close');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        explain(){
            this.setLoading(true);
            jobHeavyService.explain(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('close');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            jobHeavyService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.staffName = response.data.staff.fullName;
                    if(this.action == 'explain'){
                        this.entity.estimate.details.map(obj => {
                            obj.explainPrice = obj.estimatePrice;
                            obj.explainVat = obj.estimateVat;
                            obj.explainAmount = obj.estimateAmount;
                        })
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        addRouteRow() {
            this.entity.routes.push(
                {
                    id: this.uuidv4(this.entity.routes.length + 1), 
                    type: 1,
                    locationId: 0,
                    location: { locationName: '' }
                }
            );
        },  

        delRouteRow(item){
            const index = this.entity.routes.findIndex(x => x.id == item.id);
            this.entity.routes.splice(index, 1);
        },

        //Location
        closeLocation(item){
            const selected = this.entity.routes.findIndex(x => x.id == this.selectedId);
            this.entity.routes[selected].location = item;
            this.entity.routes[selected].locationId = item.id;
            this.$refs.locationList.close();
            this.selectedId = '';
        },

        openLocation(id){
            this.selectedId = id;
            this.$refs.locationList.open();
        },

        //Driver
        closeDriver(item){
            const selected = this.entity.vehicleList.findIndex(x => x.id == this.selectedId);
            this.entity.vehicleList[selected].driver = item;
            this.entity.vehicleList[selected].driverId = item.id;
            this.$refs.driverList.close();
            this.selectedId = '';
        },

        openDriver(id){
            this.selectedId = id;
            this.$refs.driverList.open();
        },

        //Staff 2
        closeStaff2(item){
            this.selectedObject.staff = item;
            this.selectedObject.staffId = item.id;
            this.$refs.staff2List.close();
            this.selectedId = '';
        },

        openStaff2(obj){
            this.selectedObject = obj;
            this.$refs.staff2List.open();
        },

        //Đơn vị
        closeCompany(item){
            const selected = this.entity.vehicleList.findIndex(x => x.id == this.selectedId);
            this.entity.vehicleList[selected].company = item;
            this.entity.vehicleList[selected].companyId = item.id;
            this.entity.vehicleList[selected].vehicleOutside = { licensePlate: '' };
            this.entity.vehicleList[selected].vehicle = { licensePlate: '' };
            this.$refs.companyList.close();
            this.selectedId = '';
        },

        openCompany(id){
            this.selectedId = id;
            this.$refs.companyList.open();
        },

        //Dự toán thuê ngoài
        openTransport(obj){
            this.selectedObject = obj;
            this.$refs.transportList.open();
        },

        closeTransport(item){
            this.selectedObject.company = item;
            this.selectedObject.companyId = item.id;
            this.$refs.transportList.close();
            this.selectedId = '';
        },

        //Vehicle2
        closeVehicle2(item){
            this.selectedObject.vehicle = item;
            this.selectedObject.vehicleId = item.id;
            this.$refs.vehicle2List.close();
            this.selectedId = '';
        },

        openVehicle2(obj){
            this.selectedObject = obj;
            this.$refs.vehicle2List.open();
        },

        //Vehicle
        closeVehicle(item){
            const selected = this.entity.vehicleList.findIndex(x => x.id == this.selectedId);
            this.entity.vehicleList[selected].vehicle = item;
            this.entity.vehicleList[selected].vehicleId = item.id;
            this.$refs.vehicleList.close();
            this.selectedId = '';
        },

        openVehicle(id){
            this.selectedId = id;
            const selected = this.entity.vehicleList.findIndex(x => x.id == this.selectedId);
            if(this.entity.vehicleList[selected].companyId == 1){
                this.$refs.vehicleList.open();
            }
            else{
                this.$refs.vehicleOutsideList.open(this.entity.vehicleList[selected].companyId);
            }
        },

        closeVehicleOutside(item){
            const selected = this.entity.vehicleList.findIndex(x => x.id == this.selectedId);
            this.entity.vehicleList[selected].vehicleOutside = item;
            this.entity.vehicleList[selected].vehicleId = item.id;
            this.$refs.vehicleOutsideList.close();
            this.selectedId = '';
        },

        //Sales
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.getCode(item.id);
            this.$refs.staffList.close();
        },

        getStaffSelected(item){
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.staffName = item.fullName;
            this.getCode(item.id);
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.staffName = response.data.fullName;
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getCode(staffId){
            this.setLoading(true);
            jobHeavyService.getCode(staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.jobCode = response.data.code;
                    this.entity.jobNumber = response.data.number;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        uuidv4(id) {
            let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
            );

            return uuid + "-" + id;
        },

        close(){
            this.showDialog = false;
        },
    },
    validations: {
        entity: {
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            planId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            jobCode: { required},
            jobDate: { required},
            submitDate: { required},
            vehicleList: {
                $each: {
                    companyId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    vehicleId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                }
            },
            goodsList: {
                $each: {
                    orderDetailId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    }
                }
            },
            routes: {
                $each: {
                    locationId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    }
                }
            },
        }
    },
})
</script>

<style>
    .tabs-order-vehicle .md-tabs-navigation {
        background-color: bisque !important;
        padding: 0;
        margin: 0 12px;
    }
</style>

<style scoped>
    .explain {
        width: 119%;
    }
    .editable span {
        padding-right: 5px;
    }
    .editable a:hover {
        text-decoration: underline !important;
    }
    .btn-add {
        background-color: #007bff;
        padding: 7px 10px;
        color: #FFF !important;
        border-radius: 3px;
    }
    .btn-add i {
        color: #FFF !important;
    }
    .order-detail-list {
        border: 1px solid #ccc;
    }
    .box-item:first-child {
        padding-top: 5px;
    }
    .box-item {
        display: flex;
        justify-content: flex-start;
        margin: 5px 0;
    }
    .box-item > div:first-child {
        width: 38%;
    }
    .box-item > div:last-child {
        width: 62%;
    }
    .box-value {
        padding-left: 20px;
    }
    .box-1 .box-item > div:first-child {
        width: 33%;
    }
    .box-1 .box-item > div:last-child {
        width: 67%;
    }
    .box-3 .box-item > div:first-child {
        width: 30%;
    }
    .box-3 .box-item > div:last-child {
        width: 70%;
    }
</style>

